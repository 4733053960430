import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { BsCloudLightningFill, BsFacebook } from "react-icons/bs";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { FiInstagram, FiMail, FiSend, FiPrinter } from "react-icons/fi";
import Porto from "../../assets/Portofolio-Abdul_Fikih_Kurnia.pdf";
import CV from "../../assets/CV-Abdul_Fikih_Kurnia.pdf";
import { bannerImg } from "../../assets/index";

const Left = () => {
  const [text] = useTypewriter({
    words: ["Web Designer", "Web Developer", "Android Developer", "Machine Learning Engineer", "Drone Programmer", "Database Engineer"],
    loop: true,
    typeSpeed: 30,
    deleteSpeed: 20,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-5/12 h-full bg-bodyColor rounded-2xl shadow-testShwdow z-10">
      <div className="w-full h-3/5">
        <img className="w-full h-full object-cover rounded-2xl" src={bannerImg} loading="priority" alt="bannerImage" />
      </div>
      <div className="w-full h-2/5 flex flex-col justify-between border-t-0 rounded-bl-xl rounded-br-xl">
        <div className="flex flex-col items-center gap-2 py-10">
          <h1 className="text-textColor text-4xl font-semibold">Abdul Fikih Kurnia</h1>
          <p className="text-base text-designColor tracking-wide">
            {text}
            <Cursor cursorBlinking="false" cursorStyle="|" />
          </p>
          <div className="flex justify-center gap-2 mt-2">
            <a href="https://github.com/abdfikih">
              <span className="hover:text-designColor duration-300 cursor-pointer text-xl">
                <FaGithub />
              </span>
            </a>
            <a href="https://www.linkedin.com/in/abdfikih/">
              <span className="hover:text-designColor duration-300 cursor-pointer text-xl">
                <FaLinkedin />
              </span>
            </a>
            <span className="hover:text-designColor duration-300 cursor-pointer text-xl">
              <BsFacebook />
            </span>
            <a href="https://instagram.com/abdfikih">
              <span className="hover:text-designColor duration-300 cursor-pointer text-xl">
                <FiInstagram />
              </span>
            </a>
            <a href="mailto:abdulfikihk@gmail.com">
              <span className="hover:text-designColor duration-300 cursor-pointer text-xl">
                <FiMail />
              </span>
            </a>
          </div>
        </div>
        <div className="flex h-14">
          <a href={CV} target="_blank" className="w-1/2 border-t-[1px] borderRight border-r-zinc-600 border-t-zinc-600 text-sm tracking-wide uppercase  gap-2 hover:text-designColor duration-300" rel="noreferrer">
            <button className="w-full h-full flex justify-center items-center gap-2">
              Download CV <BsCloudLightningFill />
            </button>
          </a>
          <a
            href={Porto}
            target="_blank"
            rel="noopener noreferrer"
            className="w-1/2 border-t-[1px] border-t-zinc-600 text-sm tracking-wide uppercase flex justify-center items-center gap-2 hover:text-designColor duration-300"
          >
            <button className="w-full h-full flex justify-center items-center gap-2">
              Portofolio <FiPrinter />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Left;
