import React from "react";
import { FaBuffer } from "react-icons/fa";
import { AiFillAndroid } from "react-icons/ai";
import { AiFillDatabase } from "react-icons/ai";
import { SiAntdesign } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
import { GiDeliveryDrone } from "react-icons/gi";
import ServicesCard from "./ServicesCard";

const MyServices = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <ServicesCard icons={<BiCodeAlt />} title="Web Development" subTitle="In my web development endeavors, I utilize a range of languages such as JavaScript, TypeScript, HTML, CSS, PHP, and Python, along with frameworks like React, Vue, Angular, Laravel, and ASP.NET. This diverse skill set enables me to create dynamic, interactive, and scalable web applications, selecting the most appropriate tools for each project." />
      <ServicesCard icons={<SiAntdesign />} title="Web Design" subTitle="I have a strong web design skill set, leveraging tools like Figma for collaborative and efficient design processes. My diverse tool usage, including graphic design and code editors, ensures versatility. Creating responsive designs and prioritizing user experience showcases my commitment to delivering both visually impressive and user-friendly websites." />
      <ServicesCard icons={<AiFillAndroid />} title="Android Developer" subTitle="As an Android developer, I specialize in creating robust and efficient mobile applications for the Android platform. My proficiency extends across various technologies, including Kotlin, Flutter, Swift, and React Native. I ensure the seamless functionality and performance of applications." />
      <ServicesCard icons={<FaBuffer />} title="Machine Learning" subTitle="As a machine learning enthusiast, I specialize in developing intelligent solutions using state-of-the-art technologies. My expertise includes data preprocessing, feature engineering, and model deployment. Proficient in TensorFlow and PyTorch, I've successfully applied machine learning to tasks like image recognition and natural language processing." />
      <ServicesCard icons={<AiFillDatabase />} title="Database Engineer" subTitle="I am a Database Engineer specializing in designing, implementing, and maintaining databases such as MySQL, PostgreSQL, and MongoDB. My focus is on delivering efficient data management solutions to enhance application performance." />
      <ServicesCard icons={<GiDeliveryDrone />} title="Drone Programmer" subTitle="As a drone programmer, I specialize in developing advanced algorithms for drone navigation and integration with web/app-based Ground Control Systems. My expertise lies in creating efficient and customized software solutions to enhance unmanned aerial vehicle capabilities, ensuring seamless and intelligent control during missions." />
    </div>
  );
};

export default MyServices;
